import { AdminAccessTypes, RouteItemEnum, RouteTypeEnum } from '@shared/enums';
import { IRouteItem } from '@shared/interfaces';
import * as constants from '@shared/constants';

export const DATA_STUDIO_ROUTES: IRouteItem[] = [
  // Account Hub
  {
    routeId: RouteItemEnum.AccountSpecific,
    routeLabel: 'routeLabels.accountSpecific',
    routeLink: ['/account-specific'],
    routeType: RouteTypeEnum.InternalLink,
  },
  {
    routeId: RouteItemEnum.AccountSpecificTrending,
    routeLabel: 'routeLabels.accountSpecific',
    routeLink: ['/account-specific/trending'],
    routeType: RouteTypeEnum.InternalLink,
  },
  {
    routeId: RouteItemEnum.AccountSpecificPeople,
    routeLabel: 'routeLabels.accountSpecific',
    routeLink: ['/account-specific/people'],
    routeType: RouteTypeEnum.InternalLink,
  },
  {
    routeId: RouteItemEnum.AccountSpecificActivities,
    routeLabel: 'routeLabels.accountSpecific',
    routeLink: ['/account-specific/activities'],
    routeType: RouteTypeEnum.InternalLink,
  },
  {
    routeId: RouteItemEnum.Login,
    routeLabel: '',
    routeLink: [`/${constants.LOGIN_ROUTE_PATH}`],
    routeType: RouteTypeEnum.InternalLink,
  },
  // Settings
  {
    routeId: RouteItemEnum.Settings,
    routeLabel: 'routeLabels.settings',
    routeLink: ['/settings'],
    routeType: RouteTypeEnum.InternalLink,
    alwaysAdd: true,
  },
  {
    routeId: RouteItemEnum.HubspotConfigure,
    routeLabel: 'routeLabels.settings',
    routeLink: ['/settings/self-configure/integrations/hubspot'],
    routeType: RouteTypeEnum.InternalLink,
  },
  {
    routeId: RouteItemEnum.Permissions,
    routeLabel: 'shared.navMenus.settings.permissions',
    routeLink: ['#/settings/permissions/team'],
    routeType: RouteTypeEnum.LegacyLink,
  },
  {
    routeId: RouteItemEnum.Stages,
    routeLabel: 'shared.navMenus.settings.stages',
    routeLink: ['#/settings/stages'],
    routeType: RouteTypeEnum.LegacyLink,
  },
  {
    routeId: RouteItemEnum.PagesAndFeatures,
    routeLabel: 'shared.navMenus.settings.pagesAndFeatures',
    routeLink: ['#/settings/pages-and-features/user-interface'],
    routeType: RouteTypeEnum.LegacyLink,
  },
  {
    routeId: RouteItemEnum.DataHygiene,
    routeLabel: 'shared.navMenus.settings.dataHygiene',
    routeLink: ['#/settings/data-hygiene/orphan-lead-finder'],
    routeType: RouteTypeEnum.LegacyLink,
  },
  {
    routeId: RouteItemEnum.CampaignScoring,
    routeLabel: 'shared.navMenus.settings.campaignScoring',
    routeLink: ['#/settings/attribution-weight?editable'],
    routeType: RouteTypeEnum.LegacyLink,
    permissionLevel: [AdminAccessTypes.OrgAdmin],
  },
  {
    routeId: RouteItemEnum.InternalConfiguration,
    routeLabel: 'shared.navMenus.settings.internalConfiguration',
    routeLink: ['#/settings/internal-configure/permissions'],
    routeType: RouteTypeEnum.LegacyLink,
    alwaysAdd: true,
  },
  {
    routeId: RouteItemEnum.Configuration,
    routeLabel: 'shared.navMenus.settings.configuration',
    routeLink: ['#/settings/self-configure/field-mapping'],
    routeType: RouteTypeEnum.LegacyLink,
  },
  {
    routeId: RouteItemEnum.DemoConfig,
    routeLabel: 'routeLabels.demoConfig',
    routeLink: ['demo-config'],
    routeType: RouteTypeEnum.InternalLink,
    permissionLevel: [AdminAccessTypes.BFAdmin],
  },
  {
    routeId: RouteItemEnum.SegmentIndex,
    routeLabel: 'routeLabels.segmentIndex',
    routeLink: ['segments'],
    routeType: RouteTypeEnum.InternalLink,
    alwaysAdd: true,
  },
];
